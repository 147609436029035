import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Card.css";
import moment from "moment";
// import postImage from "../../resources/ig/media/posts/201701/...";

// import ig from "../../resources/ig";

const Card = (props) => {
  const { item, setOpen, setItem, textImagePref } = props;
  // import postImage from `../../resources/ig/${item.media[0].uri}`;

  const [shortenedTitle, setShortenedTitle] = useState("");

  // const [postImage, setPostImage] = useState("");

  let prettyDate = "";
  let today = Date.now();
  if (moment(item.pubDate).isSame(today, "day")) {
    prettyDate += "Today, ";
  }

  prettyDate += moment(item.pubDate).format("MMM Do  h:mm a");

  const cardClicked = () => {
    setItem(item);
    setOpen(true);
    console.log("OPENED ITEM::", item);
  };

  // default img src on img error
  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png";
  };

  // console.log("showImage is: " + textImagePref);
  // const showImage = textImagePref ? "" : " hide-img";

  useEffect(() => {
    // let postImage =
    //   "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/ash-vintage/" +
    //   item.media[0].uri;
    // // console.log(`POST IMAGE: ${postImage}`);
    // setPostImage(postImage);
  });

  useEffect(() => {
    let title = item.title;

    if (title) {
      // Limit item title to 50 characters
      if (title.length > 50) {
        title = title.substring(0, 50) + "...";
      }
      setShortenedTitle(title);
    }
  }, [item]);

  return (
    <div
      className="clothing-card"
      onClick={() => cardClicked()}
      aria-controls="example-collapse-text"
    >
      <div className="card-img-frame">
        {item.imageLink.toString().endsWith("mp4") ? (
          <source src={item.imageLink} type="video/mp4" />
        ) : (
          <img
            className="card-img "
            alt={"card image " + item.publisher}
            onError={addDefaultSrc}
            // src={
            //   "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/ash-vintage/" +
            //   item.media[0].uri
            // }
            src={item.imageLink}
          />
        )}
        {/* <img
          className="card-img "
          alt={"card image " + item.publisher}
          onError={addDefaultSrc}
          src={postImage}
        /> */}
      </div>
      <p className="card-title">{shortenedTitle}</p>
      {/* <p className="card-publisher na-blue-font">{item.publisher}</p> */}
      {/* <p className="card-date">{prettyDate}</p> */}
      <div className="card-details">
        {/* Size Tag */}
        <div className="size-tag-container card-size">
          <div className="size-tag">
            <p className="size">{item.tagSize}</p>
          </div>
        </div>
        {/* Price  */}
        {/* <p className="card-price">${item.price}</p> */}
      </div>
    </div>
  );
};

export default Card;
