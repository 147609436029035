import React from "react";

const NavItem = (props) => {
  const pageURI = window.location.pathname + window.location.search;
  const liClassName =
    props.path === pageURI
      ? "nav-item nav-item-custom nav-item-custom-active active"
      : "nav-item nav-item-custom";
  // const aClassName = props.disabled ? "nav-link disabled" : "nav-link";

  const aClassName =
    props.path === pageURI ? "nav-link nav-link-selected" : "nav-link";
  return (
    <li className={liClassName}>
      <a href={props.path} className={aClassName}>
        {props.name}
        {props.path === pageURI ? (
          <span className="sr-only">(current)</span>
        ) : (
          ""
        )}
      </a>
    </li>
  );
};

export default NavItem;
