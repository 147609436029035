// import axios from "axios";
// import config from "./config";
// import sampleJson from "./clothing-sample-json";
import postImageJson from "./posts_1.json";

// const getArticles = async (latestDate) => {
//   // let response = await axios.get(
//   //   config.API_ENDPOINT + "api/news/beforeDate",
//   //   {
//   //     params: { date: latestDate },
//   //   }
//   // );
//   // console.log("article response: ", response);

//   return sampleJson;
// };

const ashPhotoBucketUrl =
  "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/ash-vintage/";
const buildPostJson = () => {
  console.log("Post Image Json:::", postImageJson);
  let fullPostJson = [];
  for (let post in postImageJson) {
    // console.log("POST:::", postImageJson[post]);
    let currentPost = postImageJson[post];
    let media = currentPost.media;
    let title = currentPost.title;

    if (!title && media[0].title) {
      title = media[0].title;
    }

    if (title) {
      title = title.replace(/[^\w\s]/gi, "");
    }

    let createdTimestamp = currentPost.creation_timestamp;
    var createdDate = new Date(createdTimestamp);

    let imageLink = media[0]?.uri;

    if (media.length > 1) {
      media.shift();
    }

    if (imageLink.toString().endsWith("mp4")) {
      continue;
    }

    let newMedia = [];
    // console.log("curr media:::", currMedia);

    if (media.length !== 1) {
      for (let m in media) {
        let currMedia = media[m];
        let currMediaLink = ashPhotoBucketUrl + currMedia.uri;

        if (currMediaLink.toString().endsWith("mp4")) {
          continue;
        }

        newMedia.push(currMediaLink);
      }
    }

    let curr = {
      id: post,
      title: title ? title : "Sold",
      imageLink: ashPhotoBucketUrl + imageLink,
      altImageLinks: newMedia,
      createdDate: createdDate,
      createdTimestamp: createdTimestamp,
    };

    fullPostJson.push(curr);
  }

  console.log("Full JSON:::", fullPostJson);

  return fullPostJson;
};

const getPosts = () => {
  const fullPostJson = buildPostJson();

  fullPostJson.sort((a, b) => (parseInt(a.id) < parseInt(b.id) ? 1 : -1));

  const sortedPosts = fullPostJson.sort(
    (a, b) => b.createdDate - a.createdDate
  );

  // return postImageJson;
  return sortedPosts;
};

const getSoldPosts = () => {
  let posts = getPosts();

  let soldPosts = posts.filter(function (obj) {
    let title = obj.title.toLowerCase();
    return !title.includes("sold");
  });

  return soldPosts;
};

const getUnsoldPosts = () => {
  let posts = getPosts();

  let unsoldPosts = posts.filter(function (obj) {
    let title = obj.title.toLowerCase();
    return !title.includes("sold");
  });

  // for (let i in posts) {
  //   let currTitle = posts[i].title.toLowerCase();
  //   console.log(currTitle);
  //   if (!currTitle.contains("sold")) {
  //     unsoldPosts.push(posts[i]);
  //   }
  // }

  return unsoldPosts;
};

export default {
  getPosts,
  getUnsoldPosts,
  getSoldPosts,
};
