import React, { useState, useEffect } from "react";

import { Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import useService from "./ClothingService";
import "./Clothing.css";
import Article from "../Article/Article";
import Card from "../Card/Card";

import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

// import textLogo from "../../resources/thank-you-white.svg";
// import textLogo from "../../resources/gilroy-semibold-italic.svg";

function Clothing(props) {
  const { colNum, textImagePref } = props;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState("");
  const [posts, setPosts] = useState([]);
  const [fullPosts, setFullPosts] = useState([]);
  const [postsLength, setPostsLength] = useState(12);
  const [unsoldPosts, setUnsoldPosts] = useState([]);
  const [soldPosts, setSoldPosts] = useState([]);

  const [showSold, setShowSold] = useState(true);
  const [showAvailable, setShowAvailable] = useState(true);

  const [currentList, setCurrentList] = useState(fullPosts);

  const switchList = (inputList) => {
    setCurrentList(inputList);
    setPostsLength(12);
    setPosts(inputList.slice(0, 12));
  };

  const toggleAction = () => {
    if (!showSold && showAvailable) {
      console.log("Show sold");
      switchList(soldPosts);
    } else if ((showSold, !showAvailable)) {
      console.log("Show unsold");

      switchList(unsoldPosts);
    } else {
      console.log("Show full");

      switchList(fullPosts);
    }
  };

  const toggleAvailable = () => {
    let currentAvailable = !showAvailable;
    setShowAvailable(currentAvailable);
    toggleAction();
  };

  const toggleSold = () => {
    let currentSold = !showSold;
    setShowSold(currentSold);
    toggleAction();
  };

  const loadPosts = () => {
    const fullPosts = useService.getPosts();
    setFullPosts(fullPosts);
    setPosts(fullPosts.slice(0, postsLength));
    setCurrentList(fullPosts);

    const unsoldPosts = useService.getUnsoldPosts();
    setUnsoldPosts(unsoldPosts);

    const soldPosts = useService.getSoldPosts();
    setSoldPosts(soldPosts);

    // console.log("POSTS");
    // console.log(posts[0]);
    // console.log(posts[0].title);
    // console.log(posts[0].media[0].uri);
  };

  useEffect(() => {
    // retrieveArticles(Date.now());
    loadPosts();
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  useEffect(() => {
    setIsLoaded(true);
  }, [posts]);

  const loadNextPage = () => {
    // let lastItemDate = items[items.length - 1].pubDate;
    // retrieveArticles(lastItemDate);
    let newPostsLength =
      postsLength + 12 >= currentList.lenth
        ? currentList.length
        : postsLength + 12;

    console.log("CURRENT LIST:::", currentList);
    console.log("Current POSTS:::", posts);
    console.log("newPostsLength", newPostsLength);
    console.log("postsLength", postsLength);

    setPosts(currentList.slice(0, newPostsLength));
    setPostsLength(newPostsLength);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <React.Fragment>
        <Nav />
        <Article
          showArticle={open}
          item={item}
          setOpen={setOpen}
          isOpen={open}
        />
        <div
          className={
            open
              ? "container clothing-container uk-animation-fade uk-animation-slide-bottom-small article-margin"
              : "container clothing-container uk-animation-fade uk-animation-slide-bottom-small no-margin"
          }
        >
          {/* <img src={textLogo} className="" /> */}
          <h1 className="clothing-title">CLOTHING</h1>
          {/* <div className="checkBox-div">
            <label class="checkBox-container">
              Available
              <input
                type="checkbox"
                checked={showAvailable}
                onChange={() => toggleAvailable()}
              ></input>
              <span class="checkmark"></span>
            </label>
          </div>
          <div className="checkBox-div">
            <label class="checkBox-container">
              Sold
              <input
                type="checkbox"
                checked={showSold}
                onChange={() => toggleSold()}
              ></input>
              <span class="checkmark"></span>
            </label>
          </div> */}
          <div className="clothing-grid row">
            {posts.map((item) => (
              // 12-single, 6-double, 4-triple, 3-quad,
              // <Col xs={12} sm={12} md={6} lg={4}>
              // <Col xs={colNum}>
              <Col xs={6} sm={6} md={4} lg={3} key={item.id}>
                <Card
                  item={item}
                  setOpen={setOpen}
                  isOpen={open}
                  setItem={setItem}
                  textImagePref={textImagePref}
                />
              </Col>
            ))}
            {/* <button
              className="button2"
              // btn btn-dark load-more-btn no-bs-border
              onClick={() => loadNextPage()}
            >
              Load More
            </button> */}
            <a onClick={() => loadNextPage()} className=" load-more-btn-2">
              Load More
            </a>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Clothing;
