import React from "react";
// import "bootstrap/dist/css/bootstrap.css";
import "./About.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

function Home(props) {
  return (
    <React.Fragment>
      <Nav />
      <div className="about-container uk-animation-fade uk-animation-slide-bottom-small">
        <h1 className="about-header">ABOUT</h1>
        <h2 className="about-text">
          Ash Vintage is a vintage fashion brand founded and inspired by the
          city of Chicago. We were born out of the desire to find cool clothes,
          hang out with friends, and do creative stuff. Our hope is that you
          find something you love, wear it with confidence, and build a
          purposeful community that encourages individuality, expressiveness,
          and vision.
        </h2>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Home;
