import React from "react";
import Clothing from "../components/Clothing/Clothing";
import Home from "../components/Home/Home";
import Contact from "../components/Contact/Contact";
import About from "../components/About/About";

const routes = {
  "/": () => <Home />,
  "/clothing": () => <Clothing />,
  "/contact": () => <Contact />,
  "/about": () => <About />,
};
export default routes;
