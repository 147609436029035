import React from "react";
import "./App.css";
// import Navigation from "./components/Navigation";
import Nav from "./components/Nav/Nav";
import Clothing from "./components/Clothing/Clothing";

import { useRoutes } from "hookrouter";
import Routes from "./services/routes";

function App() {
  const routeResult = useRoutes(Routes);
  return routeResult;
  // return (
  //   <div className="App">
  //     <header className="App-header">

  //       <Clothing />
  //     </header>
  //   </div>
  // );
}

export default App;
