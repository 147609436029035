import React, { useState } from "react";
import "./Nav.css";
import "../theme.css";
import NavItem from "./NavItem";
import ashLogo from "../../resources/ash-logo.JPG";

// import threeDCubeImage from "../../resources/3d-cube.svg";

// import Home from "../Home/Home";

const Nav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [colNum, setColNum] = useState(6);
  const [textImagePref, setTextImagePref] = useState(true);

  const [isToggleOn, setIsToggleOn] = useState(false);
  const [isImageToggleOn, setIsImageToggleOn] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const colDropDownItemClicked = (col) => {
    setMenuOpen(false);
    setIsToggleOn(!isToggleOn);
    setColNum(col);
  };

  const imgDropDownItemClicked = (pref) => {
    setMenuOpen(false);
    setIsImageToggleOn(!isImageToggleOn);
    setTextImagePref(pref);
  };

  // const hideImageDropDown = () => {
  //   setIsImageToggleOn(false);
  // };

  const show = menuOpen ? "show" : "";
  const navBorderRadius = menuOpen ? "navbar-radius" : "";

  return (
    <React.Fragment>
      <div className="nav-container">
        <nav
          className={
            "navbar navbar-expand-lg navbar-light custom-navbar" +
            navBorderRadius
          }
        >
          <a className="nav-link-logo" href="/">
            <img src={ashLogo} className="nav-logo" />

            <h1 className="nav-company-name">
              {/* Mendenhall<span className="smith">Smith</span>
              <span className="structural">STRUCTURAL</span> */}
            </h1>
          </a>

          <button
            className="navbar-toggler no-bs-border"
            type="button"
            onClick={() => toggleMenu()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={"collapse  navbar-collapse " + show}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <NavItem path="/" name="HOME" />
              <NavItem path="/clothing" name="CLOTHING" />
              <NavItem path="/about" name="ABOUT" />
              {/* <NavItem path="/careers" name="CAREERS" /> */}
              <NavItem path="/contact" name="CONTACT" />
            </ul>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Nav;
