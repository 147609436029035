import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Home.css";
// import Nav from "../Nav/Nav";

import textLogo from "../../resources/thank-you.svg";
// import homeBG from "../../resources/home-bg.jpeg";

function Home(props) {
  return (
    <React.Fragment>
      {/* <Nav /> */}
      {/* <img src={homeBG} className="home-bg" /> */}
      <div className="home-container">
        <img
          src={textLogo}
          className="text-logo uk-animation-fade"
          alt="ash vintage logo"
        />
        <div className="btn-container uk-animation-fade uk-animation-slide-bottom-small">
          <a href="/clothing" class="button2">
            CLOTHING
          </a>
          <a href="/about" class="button2">
            ABOUT
          </a>
          <a href="https://www.instagram.com/ash.vntg/" target="_blank">
            <i className="fa fa-instagram ig-icon"></i>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home;
